export const dashboardRoutes = {
  label: "Dashboard",
  labelDisable: true,
  children: [
    {
      name: "Dashboard",
      icon: "chart-pie",
      to: "/dashboard",
      exact: true,
      active: true,
    },
  ],
};

export const giveawayRoutes = {
  label: "Giveaways",
  children: [
    {
      name: "Giveaways",
      icon: "gift",
      to: "/giveaways",
      active: true,
    },
  ],
};

export const businessAffiliateRoutes = {
  label: "Partnerships",
  children: [
    {
      name: "Business Affiliates",
      icon: "user",
      to: "/partners",
      active: true,
    },
    {
      name: "Sponsors",
      icon: "handshake",
      to: "/sponsors",
      active: true,
    },
  ],
};

export const variableRoutes = {
  label: "Filters & Options",
  children: [
    {
      name: "Product Types",
      icon: "shapes",
      to: "/product/types",
      active: true,
    },
    {
      name: "Product Shapes",
      icon: "shapes",
      to: "/product/shapes",
      active: true,
    },
    {
      name: "Product Patterns",
      icon: "shapes",
      to: "/product/patterns",
      active: true,
    },
  ],
};

export const salesMetadata = {
  label: "Orders",
  children: [
    {
      name: "Orders",
      icon: "shopping-cart",
      to: "/orders",
      active: true,
    },
    {
      name: "Live Auction Reports",
      icon: "camera",
      to: "/live/reports",
      active: true,
    },
  ],
};

export const appContent = {
  label: "App Content",
  children: [
    {
      name: "Products",
      icon: "shopping-cart",
      to: "/products",
      active: true,
    },
    {
      name: "Podcasts",
      icon: "puzzle-piece",
      to: "/podcasts",
      active: true,
    },
  ],
};

export const userData = {
  label: "Customers & Vendors",
  children: [
    {
      name: "Customers",
      icon: ["fas", "users"],
      to: "/customers",
      active: true,
    },
    {
      name: "Vendors",
      icon: ["fas", "users"],
      to: "/vendors",
      active: true,
    },
  ],
};

export const moderationTools = {
  label: "Platform Monitoring",
  children: [
    {
      name: "User Reports",
      icon: "question-circle",
      to: "/support",
      active: true,
    },
  ],
};

export default [
  dashboardRoutes,
  userData,
  giveawayRoutes,
  businessAffiliateRoutes,
  variableRoutes,
  salesMetadata,
  appContent,
  moderationTools,
];
