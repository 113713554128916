import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const ChartComponent = ({ data }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (data && Array.isArray(data)) {
      const labels = data.map((item) => item.month);
      const newUsers = data.map((item) => item.newUsers);

      setChartData({
        labels: labels,
        datasets: [
          {
            label: "New Users Per Month",
            data: newUsers,
            borderColor: "rgba(255, 255, 255, 1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderWidth: 1.5,
            pointBackgroundColor: "rgba(255, 255, 255, 1)",
            pointBorderColor: "rgba(255, 99, 132, 0.2)",
            pointHoverBackgroundColor: "rgba(255, 99, 132, 1)",
            pointHoverBorderColor: "rgba(255, 99, 132, 0.2)",
            fill: true, // Fill the area under the line
          },
        ],
      });
    }
  }, [data]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
        labels: {
          color: "#fff", // Color of legend text
          font: {
            size: 14, // Size of legend text
          },
        },
      },
      title: {
        display: true,
        text: "New Users (Last 18 Months)",
        color: "#fff",
        font: {
          size: 16,
        },
        padding: {
          top: 16,
          bottom: 24,
        },
      },
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        titleColor: "#fff",
        bodyColor: "#fff",
        borderColor: "#fff",
        borderWidth: 1,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#fff", // Color of x-axis labels
          maxRotation: 0, // Ensure labels are displayed straight
          minRotation: 0, // Ensure labels are displayed straight
        },
        grid: {
          display: true,
          color: "rgba(200, 200, 200, 0.3)",
        },
      },
      y: {
        ticks: {
          color: "#fff", // Color of y-axis labels
        },
        grid: {
          color: "rgba(200, 200, 200, 0.3)",
        },
      },
    },
  };

  if (!chartData) {
    return <div>Loading...</div>;
  }

  return (
    <div
      style={{
        backgroundColor: "#040093",
        borderRadius: 12,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 8,
        paddingBottom: 8,
      }}
    >
      <Line data={chartData} options={options} />
    </div>
  );
};

export default ChartComponent;
