import LoadingIcon from "common_components/LoadingIcon";
import React, { useEffect, useState } from "react";

import moment from "moment";
import { HiMiniChevronRight } from "react-icons/hi2";
import Http from "security/Http";
import { useNavigate } from "react-router";

const Orders = () => {
  const navigation = useNavigate();
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  const getOrders = () => {
    Http.callApi(["get", "admin/orders/get"]).then((response) => {
      console.log("Orders Response: ", response.data);

      if (!response.data.success) {
        alert(response.data?.message ?? "");
        return;
      }

      setOrders(response.data.data);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  };

  useEffect(() => {
    document.title =
      "Orders | Opal Empire & Jewellery | Buy & Sell Opal Worldwide";
    getOrders();
  }, []);

  return (
    <>
      {/* Header Area */}
      <div
        className="d-flex align-items-center bg-white px-3 py-3 mb-4"
        style={{ width: "100%", borderRadius: 8 }}
      >
        <h4 className="mb-0 pb-0">Orders</h4>

        <div style={{ flex: 1 }} />

        <h4 className="mb-0 pb-0">{orders ? orders?.length : 0} Found</h4>
      </div>

      {/* Content Area */}
      {!loading ? (
        <div className="row">
          {orders && orders.length > 0
            ? orders.map((order) => {
                const orderDate = moment(
                  order?.createdAt ?? new Date().toString()
                ).format("do MMMM yyyy");
                return (
                  <div
                    onClick={() => navigation(`/order/${order._id}`)}
                    key={order._id}
                    className="col-3 px-2 mb-3 cursor-pointer"
                  >
                    <div
                      className="bg-white position-relative"
                      style={{ borderRadius: 8 }}
                    >
                      <div className="px-3 py-3 d-flex justify-items-center align-items-center">
                        <div>
                          <p
                            className="pb-4 mb-0"
                            style={{
                              color: "#7f37da",
                              fontWeight: "500",
                              height: 32,
                            }}
                          >
                            {order?.business
                              ? order?.business?.name
                              : order?.business_id?.name ?? ""}
                          </p>

                          <p
                            className="pb-3 mb-0"
                            style={{
                              color: "#000000",
                              fontWeight: "600",
                              height: 32,
                            }}
                          >
                            Order:{" "}
                            {order?.order_no
                              ? order.order_no?.toUpperCase()
                              : ""}
                          </p>

                          <p
                            className="pb-2 mb-0"
                            style={{
                              height: 32,
                              color: "#3b37da",
                              overflow: "hidden",
                            }}
                          >
                            {order?.customer
                              ? order?.customer?.first_name
                              : order?.customer_id?.first_name}{" "}
                            {order?.customer
                              ? order?.customer?.last_name
                              : order?.customer_id?.last_name}
                          </p>

                          <p
                            className="pb-0 mb-0"
                            style={{
                              height: 24,
                              color: "#000000",
                              overflow: "hidden",
                            }}
                          >
                            {orderDate}
                          </p>
                        </div>

                        <div style={{ flex: 1 }} />

                        <HiMiniChevronRight
                          size={24}
                          style={{
                            color: "#000000", // Optional: Match the badge color or use a different one
                            zIndex: "1",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      ) : (
        <>
          <div
            style={{
              height: "70vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingIcon />
            <p style={{ color: "#000000", fontWeight: "500" }}>
              Updating Orders
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default Orders;
