import "./OpalLive.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import io from "socket.io-client";

import {
  HiOutlineLightBulb,
  HiLightBulb,
  HiZoomIn,
  HiZoomOut,
} from "react-icons/hi";
import { RiArrowLeftLine, RiAuctionFill } from "react-icons/ri";

import AgoraRTC from "agora-rtc-sdk-ng";
import Http from "security/Http";
import url from "../../Development.json";
// import party from "party-js";
import { errorResponse } from "../helpers/response";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiLoader, FiSend } from "react-icons/fi";
import uuid from "react-uuid";
import { BsChevronRight } from "react-icons/bs";

const socket = io("https://socket.opalempire.com.au");

function OpalLiveControls() {
  const navigation = useNavigate();
  const [auctionId, setAuctionId] = useState("");
  const [liveAuctionEnd, setLiveAuctionEnd] = useState(false);
  const [flash, setFlash] = useState(false);
  const [latestBidPrice, setLatestBidPrice] = useState(0.0);
  const [dataAuction, setDataAuction] = useState({});
  const [activeBids, setActiveBids] = useState([]);
  const [messages, setMessages] = useState([]);
  const [loadingStream, setLoadingStream] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isSkippable, setIsSkippable] = useState(false);

  const [currentItem, setCurrentItem] = useState({});

  const [totalBidRecords, setTotalBidRecords] = useState([]);

  const [showEndOfStream, setShowEndOfStream] = useState(false);

  const controlModal = (value) => {
    setModalIsOpen(value);
  };

  let client = null;

  useEffect(() => {
    console.log("DATA AUCTION", dataAuction);
    setTimeout(() => {
      auctionListing();
    }, 500);
  }, []);

  const auctionListing = () => {
    Http.callApi(url.customer_all_auction)
      .then((response) => {
        console.log("LISTINGS DATA", response.data);
        setLoadingStream(false);
        if (response.data.length > 0) {
          console.log("First Auction", response.data[0]);
          console.log("First Auctions ID", response.data[0]._id);
          setDataAuction(response.data[0]);
          setLatestBidPrice(response.data[0].items[0].last_bid_price);
          setAuctionId(response.data[0]._id);
          joinSocket({ auction: response.data[0]._id });
          setupSocket();
          setTimeout(() => {
            agoraGenrateData({ auction: response.data[0]._id });
          }, 1500);
        }
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  const joinSocket = ({ auction }) => {
    socket.emit("auction", {
      type: 1, // join
      userId: "63cbf5e675a260f6105c2ed1",
      auctionId: auction,
    });
  };

  const agoraGenrateData = ({ auction }) => {
    console.log("Generating Agora Details");
    const stremData = {
      isPublisher: 2,
      channel: auction,
    };

    console.log(stremData);
    console.log("Calling API Here");
    Http.callApi(url.AgoraRTC_token, stremData)
      .then((response) => {
        console.log("RTC TOKEN", response);

        console.log(response.data.token);
        console.log(response.data.uid);

        setTimeout(() => {
          AudienceLiveAuction({
            token: response.data.token,
            userId: response.data.uid,
            channel: auction,
          });
        }, 2500);
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  };

  const AudienceLiveAuction = async ({ token, userId, channel }) => {
    let options = {
      appId: "0d7fd61d0ef245f68a787486cdc74298",
      channel: channel,
      token: token,
      uid: userId,
    };
    client = AgoraRTC.createClient({
      mode: "live",
      role: "audience",
      codec: "h264",
      httpRetryConfig: {
        timeout: 20,
        timeoutFactor: 10,
        maxRetryCount: 20,
        maxRetryTimeout: 20,
      },
    });
    console.log("OPTIONS", options);
    const uid = await client.join(
      options.appId,
      options.channel,
      options.token,
      options.uid
    );
    console.log(uid);
    client.on("user-published", async (user, mediaType) => {
      console.log(user);
      console.log(mediaType);
      await client.subscribe(user, mediaType);
      if (mediaType === "video") {
        const remoteVideoTrack = user.videoTrack;
        const remotePlayerContainer = document.createElement("div");
        const audiencestreamWrapper = document.getElementById(
          "audienceStreamWrapper"
        );
        remotePlayerContainer.id = user.uid.toString();
        remotePlayerContainer.style.width = "900px";
        remotePlayerContainer.style.height = "480px";
        audiencestreamWrapper.append(remotePlayerContainer);
        remoteVideoTrack.play(remotePlayerContainer);
      }

      if (mediaType === "audio") {
        const remoteAudioTrack = user.audioTrack;
        remoteAudioTrack.play();
      }
      setLiveAuctionEnd(true);
    });
    client.on("user-unpublished", (user) => {
      setLiveAuctionEnd(false);
      const remotePlayerContainer = document.getElementById(user.uid);
      remotePlayerContainer.remove();
    });
  };

  const setupSocket = () => {
    socket.on("connect", function (event) {
      console.log("Socket Connection Opened Successfully");
    });

    socket.on("message", function (event) {
      console.log("Message from Server: ", event.data);
    });

    socket.on("disconnect", function (event) {
      console.log("Socket connection closed");
    });

    socket.on("auction", function (event) {
      console.log("Received Event");
      console.log(event);

      if (event["type"] === "message") {
        console.log("Message Event");

        var data = {
          type: event["type"],
          id: uuid(),
          message: event["message"],
          image: event["image"],
          name: event["name"],
        };

        var messageData = messages ?? [];
        messageData.push(data);
        setMessages([...messageData]);
        scrollToBottom("chat_frame");
      }

      if (event["type"] === "bid") {
        console.log("Bid Event");
        var data = {
          type: "bid",
          price: event["bid"]["bid_price"],
          message: `NEW BID: $${event["bid"]["bid_price"]}`,
          image: event["bid"]["customer"]["image"],
          name: `${event["bid"]["customer"]["first_name"]} ${event["bid"]["customer"]["last_name"]}`,
        };
        var bids = activeBids ?? [];
        bids.push(data);
        setActiveBids(bids);
        setLatestBidPrice(event["bid"]["bid_price"]);

        var messageData = messages ?? [];
        messageData.push(data);
        setMessages([...messageData]);
        scrollToBottom("chat_frame");

        console.log(activeBids);
      }
    });

    socket.on("error", function (event) {
      console.error("Socket error: ", event);
      console.log(event);
    });
  };

  function scrollToBottom(elementId) {
    var element = document.getElementById(elementId);
    element.scrollTop = element.scrollHeight - element.clientHeight;
  }

  const toggleFlash = () => {
    socket.emit("toggleFlash", {
      to: auctionId,
      toggle: flash,
    });
    setFlash(!flash);
  };

  const sendMessage = () => {
    const messageInput = document.getElementById("chat_message_input");
    const message = messageInput.value;
    console.log(message);

    const messageDataLocal = {
      userId: "63cbf5e675a260f6105c2ed1",
      auctionId: auctionId,
      message: message,
    };

    console.log(messageDataLocal);

    socket.emit("newAuctionMessage", JSON.stringify(messageDataLocal));
    console.log("Sending via Socket");

    scrollToBottom("chat_frame");
    document.getElementById("chat_message_input").value = "";
  };

  const zoomIn = () => {
    socket.emit("zoomIn", {
      to: auctionId,
    });
  };

  const zoomOut = () => {
    socket.emit("zoomOut", {
      to: auctionId,
    });
  };

  const markAsSold = ({ target, item }) => {
    target.preventDefault();

    socket.emit("stopBidding", {
      auctionId: auctionId,
      itemId: item._id,
    });

    if (dataAuction.item == null) {
      toast.error("No Available Items Remaining");
    }
    console.log(item);
    const data = {
      item_id: dataAuction.item._id,
      auction_id: auctionId,
    };

    // Create a copy of the auctionData state
    var savedAuctionData = dataAuction;

    var auctionData = dataAuction;

    const index = auctionData.items.findIndex((itemData) => {
      return itemData._id === item._id;
    });

    // Save the item before deleting it
    const itemToBeSold = auctionData.items[index];

    console.log(index);
    if (index !== -1) {
      const updatedItems = [...auctionData.items]; // Create a copy of items array
      updatedItems.splice(index, 1); // Remove the item at the specified index

      setDataAuction({ ...auctionData, items: updatedItems }); // Update the auctionData state with the updated items array
    }

    console.log("Marked Sold", data);
    Http.callApi(url.award_bid_user, data)
      .then((response) => {
        console.log("SOLD DATA", response.data);

        // Add the sold item to the totalBidRecords array
        var totalBids = totalBidRecords;
        totalBids.push({
          item: itemToBeSold,
          customer: response.data.data,
          price: latestBidPrice,
        });

        // Save the totalBidRecords array to the state with the new item including the past data
        setTotalBidRecords([...totalBids]);

        toast.success(
          `Item Sold Successfully to ${response.data.data.first_name} ${response.data.data.last_name}. They have been notified!`
        );
        controlModal(true);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  const skipToNextItem = ({ target }) => {
    target.preventDefault();

    socket.emit("stopBidding", {
      auctionId: auctionId,
      itemId: dataAuction._id,
    });

    setIsSkippable(true);

    var activeData = dataAuction;

    var auctionData = dataAuction;

    if (selectedIndex !== -1) {
      const updatedItems = [...auctionData.items]; // Create a copy of items array

      setCurrentItem(updatedItems[selectedIndex]);
      updatedItems.splice(selectedIndex, 1); // Remove the item at the specified index

      setDataAuction({ ...auctionData, items: updatedItems }); // Update the auctionData state with the updated items array
    }

    controlModal(true);
  };

  const selectNextItem = ({ item }) => {
    console.log(item);

    var auctionData = dataAuction;
    auctionData.item = item;

    const data = {
      item_id: item._id,
      auction_id: auctionId,
    };

    if (!isSkippable) {
      Http.callApi(url.select_new_auction_item, data)
        .then((response) => {
          console.log("SOLD DATA", response.data);
          console.log("NEXT ITEM", item);
          setLatestBidPrice(item.last_bid_price);
          toast.success("Lets go! Time to sell!");
          controlModal(false);

          const index = auctionData.items.findIndex((itemData) => {
            return itemData._id === item._id;
          });

          if (index !== -1) {
            setSelectedIndex(index);

            setTimeout(() => {
              socket.emit("startBidding", {
                auctionId: auctionId,
                itemId: dataAuction._id,
              });
            }, 2000);
          }
        })
        .catch((error) => {
          if (error.response) {
            errorResponse(error);
          }
        });
    } else {
      skipNextItem({ item });
    }
  };

  const skipNextItem = ({ item }) => {
    console.log(currentItem);
    var auctionData = currentItem.first;

    const data = {
      item_id: currentItem._id,
      auction_id: auctionId,
    };

    socket
      .emitWithAck("placeBid", {
        auction_id: auctionId,
        bid_price: parseInt(currentItem.last_bid_price + 1),
        item_id: currentItem._id,
        customer_id: "63cbf6939b0371f51d1bd535",
      })
      .then((value) => {
        console.log(value);

        Http.callApi(url.award_bid_user, data)
          .then((response) => {
            console.log("SOLD DATA", response.data);

            setTimeout(() => {
              const auctionItemId = {
                item_id: item._id,
                auction_id: auctionId,
              };

              Http.callApi(url.select_new_auction_item, auctionItemId)
                .then((response) => {
                  console.log("SOLD DATA", response.data);
                  console.log("NEXT ITEM", item);
                  setLatestBidPrice(item.last_bid_price);
                  toast.success("Lets go! Time to sell!");
                  controlModal(false);

                  const index = auctionData.items.findIndex((itemData) => {
                    return itemData._id === item._id;
                  });

                  if (index !== -1) {
                    setSelectedIndex(index);
                  }

                  setIsSkippable(false);

                  setTimeout(() => {
                    socket.emit("startBidding", {
                      auctionId: auctionId,
                      itemId: dataAuction._id,
                    });
                  }, 1000);
                })
                .catch((error) => {
                  if (error.response) {
                    errorResponse(error);
                  }
                });
            }, 750);
          })
          .catch((error) => {
            if (error.response) {
              errorResponse(error);
            }
          });
      });
  };

  return (
    <>
      <Modal isOpen={modalIsOpen} onRequestClose={controlModal}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h4>Select Next Auction Item</h4>
          {dataAuction &&
          dataAuction.items !== undefined &&
          dataAuction.items.length > 0 ? (
            dataAuction.items.map((item) => {
              console.log(item);
              return (
                <div
                  onClick={() => {
                    selectNextItem({ item: item });
                  }}
                  key={item.id}
                  style={{
                    marginTop: "0px",
                    borderColor: "#e3e3e3",
                    borderWidth: 1,
                  }}
                >
                  <div
                    className="row px-4 py-3"
                    style={{ borderRadius: "8px", marginBottom: "4px" }}
                  >
                    <img
                      alt="Chat User"
                      src={item.image[0].item}
                      style={{
                        height: "48px",
                        width: "48px",
                        padding: "0px",
                        objectFit: "cover",
                        borderRadius: "24px",
                        display: "flex",
                      }}
                    />
                    <div className="col">
                      <p className="px-2 d-flex w-75 mb-0 text-black">
                        {item.name}
                      </p>
                      <p
                        style={{ color: "black", fontSize: 14 }}
                        className="w-100 d-flex px-2 mb-0 text-black text-start"
                      >
                        Starting Price: ${item.price}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No remaining auction items for this stream
              </p>
              <a
                style={{
                  width: "100%",
                  textAlign: "center",
                  backgroundColor: "#3B37DA",
                  padding: "8px",
                  borderRadius: "12px",
                }}
                onClick={() => {
                  controlModal();
                  setShowEndOfStream(!showEndOfStream);
                }}
              >
                <button style={{ color: "white" }}>End Stream</button>
              </a>
            </div>
          )}
        </div>
      </Modal>
      <Modal
        isOpen={showEndOfStream}
        onRequestClose={() => {
          setShowEndOfStream(!showEndOfStream);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h4>End of Stream Report</h4>
          {totalBidRecords.length > 0 ? (
            totalBidRecords.map((item) => {
              console.log(item);
              return (
                <div
                  onClick={() => {
                    setShowEndOfStream(!showEndOfStream);
                  }}
                  key={item.id}
                  style={{
                    marginTop: "0px",
                    borderColor: "#e3e3e3",
                    borderWidth: 1,
                  }}
                >
                  <div
                    className="row px-4 py-3"
                    style={{ borderRadius: "8px", marginBottom: "4px" }}
                  >
                    <img
                      alt="Chat User"
                      src={item.item.image[0].item}
                      style={{
                        height: "48px",
                        width: "48px",
                        padding: "0px",
                        objectFit: "cover",
                        borderRadius: "24px",
                        display: "flex",
                      }}
                    />
                    <div className="col">
                      <p className="px-2 d-flex w-75 mb-0 text-black"></p>
                      <p
                        style={{ color: "black", fontSize: 14 }}
                        className="w-100 d-flex px-2 mb-0 text-black text-start"
                      >
                        {item.item.name}
                      </p>
                      <p
                        style={{ color: "black", fontSize: 14 }}
                        className="w-100 d-flex px-2 mb-0 text-black text-start mb-1"
                      >
                        Won by: {item.customer.first_name}{" "}
                        {item.customer.last_name}
                      </p>
                      <p
                        style={{ color: "black", fontSize: 14 }}
                        className="w-100 d-flex px-2 mb-0 text-green text-start"
                      >
                        Won for: ${item.price}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No remaining auction items for this stream
              </p>
              <a
                style={{
                  width: "100%",
                  textAlign: "center",
                  backgroundColor: "#3B37DA",
                  padding: "8px",
                  borderRadius: "12px",
                }}
                onClick={() => {
                  controlModal();
                }}
              >
                <button style={{ color: "white" }}>End Stream</button>
              </a>
            </div>
          )}
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <a
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: "#3B37DA",
                padding: "8px",
                borderRadius: "12px",
              }}
              onClick={() => {
                setShowEndOfStream(!showEndOfStream);
              }}
            >
              <button style={{ color: "white" }}>Close Report</button>
            </a>
          </div>
        </div>
      </Modal>

      <div className="App">
        {!loadingStream ? (
          <>
            <p
              style={{
                color: "white",
                paddingTop: 24,
                paddingBottom: 0,
                marginBottom: 0,
              }}
            >
              Opal Live Control Panel (Beta 3 (ISDevKit-2024.20.2-34091))
            </p>
            <div className="grid-container">
              <div className="left-column">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    className="cursor-pointer"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      navigation("/");
                    }}
                  >
                    <RiArrowLeftLine
                      color="#ffffff"
                      size={24}
                      fontWeight={400}
                    />
                    <p
                      style={{
                        color: "#ffffff",
                        marginLeft: 12,
                        marginBottom: 0,
                        paddingBottom: 0,
                      }}
                    >
                      Back to Opal Empire
                    </p>
                  </div>

                  <div style={{ flex: 1 }} />

                  <div className="current_price">
                    ${latestBidPrice !== 0.0 ? latestBidPrice.toFixed(2) : 0.0}
                  </div>
                </div>
                <div
                  className="video_frame"
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  {dataAuction ? (
                    <div
                      id="audienceStreamWrapper"
                      className="img-fluid h-100 d-flex"
                    />
                  ) : (
                    <div
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          paddingLeft: "12px",
                          color: "white",
                        }}
                      >
                        {" "}
                        No Live Streams currently active
                      </p>
                    </div>
                  )}
                </div>
                <div
                  className="video_controls"
                  style={{ justifyContent: "space-evenly" }}
                >
                  <p style={{ color: "white", fontWeight: "600" }}>
                    Live Stream Controls
                  </p>

                  <button
                    className="flash_btn"
                    onClick={(e) => skipToNextItem({ target: e })}
                  >
                    <h1
                      style={{
                        color: "#ffffff",
                        display: "flex",
                        fontSize: 32,
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "unset",
                        marginBottom: "unset",
                      }}
                    >
                      {" "}
                      <BsChevronRight size={28} />
                    </h1>
                  </button>

                  <button className="flash_btn" onClick={() => toggleFlash()}>
                    <h1
                      style={{
                        color: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "unset",
                        marginBottom: "unset",
                      }}
                    >
                      {flash ? (
                        <HiLightBulb size={38} />
                      ) : (
                        <HiOutlineLightBulb size={38} />
                      )}
                    </h1>
                  </button>

                  <button className="flash_btn" onClick={() => zoomIn()}>
                    <h1
                      style={{
                        color: "#ffffff",
                        display: "flex",
                        fontSize: 32,
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "unset",
                        marginBottom: "unset",
                      }}
                    >
                      {" "}
                      <HiZoomIn />
                    </h1>
                  </button>

                  <button className="flash_btn" onClick={() => zoomOut()}>
                    <h1
                      style={{
                        color: "#ffffff",
                        display: "flex",
                        fontSize: 32,
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "unset",
                        marginBottom: "unset",
                      }}
                    >
                      {" "}
                      <HiZoomOut />
                    </h1>
                  </button>

                  <button
                    className="flash_btn"
                    onClick={(e) =>
                      markAsSold({ target: e, item: dataAuction.item })
                    }
                  >
                    <h1
                      style={{
                        color: "#ffffff",
                        display: "flex",
                        fontSize: 32,
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "unset",
                        marginBottom: "unset",
                      }}
                    >
                      {" "}
                      <RiAuctionFill />
                    </h1>
                  </button>

                  {showMessage ? (
                    <div className="pt-4">
                      <div
                        className="pt-3 pb-3"
                        style={{
                          backgroundColor: "green",
                          borderRadius: "12px",
                        }}
                      >
                        <p style={{ color: "white", margin: "0px" }}>
                          {message}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="right-column">
                <div
                  id="chat_frame"
                  className="chat_frame"
                  style={{
                    flexGrow: 1,
                    paddingTop: "8px",
                    overflowY: "scroll",
                    position: "relative",
                    scrollbarWidth: "none",
                    display: messages.length > 0 ? "block" : "flex",
                    justifyContent: "center",
                  }}
                >
                  {messages.length > 0 ? (
                    messages.map((message) => {
                      console.log(message.type);
                      console.log(message);
                      return (
                        <div key={message.id} style={{ marginTop: "0px" }}>
                          <div
                            className="row px-4 py-3"
                            style={{
                              backgroundColor: "#00000095",
                              borderRadius: 4,
                              marginBottom: "4px",
                            }}
                          >
                            <img
                              alt="Chat User"
                              src={message.image}
                              style={{
                                height: "48px",
                                width: "48px",
                                padding: "0px",
                                objectFit: "cover",
                                borderRadius: "50px",
                                display: "flex",
                              }}
                            />
                            <div className="col">
                              <p className="px-2 d-flex w-75 mb-0 text-white">
                                {message.name}
                              </p>
                              <p
                                style={{
                                  color:
                                    message.type === "bid" ? "green" : "white",
                                }}
                                className="w-100 d-flex px-2 mb-0 text-white text-start"
                              >
                                {message.message}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      style={{
                        display: messages.length > 0 ? "block" : "flex",
                        marginTop: "0px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ color: "black" }}>No chat messages yet</p>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    backgroundColor: "#3B37DA",
                    height: 64,
                    paddingTop: 14,
                    paddingBottom: 14,
                    paddingLeft: 12,
                    paddingRight: 12,
                    display: "flex",
                    justifyContent: "start",
                    marginTop: "24px",
                    borderRadius: 64,
                    alignItems: "center",
                  }}
                >
                  <input
                    id="chat_message_input"
                    style={{
                      width: "87%",
                      paddingLeft: 16,
                      paddingRight: 16,
                      height: "45px",
                      borderRadius: 48,
                      borderWidth: "1px",
                      borderColor: "#e3e3e3",
                      alignItems: "center",
                    }}
                  />

                  <div style={{ flex: 1 }} />
                  <a
                    onClick={() => sendMessage()}
                    className="cursor-pointer"
                    style={{
                      width: 45,
                      textAlign: "center",
                      justifyContent: "center",
                      backgroundColor: "#ffffff",
                      borderRadius: 64,
                      height: 45,
                      marginLeft: 12,
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    <FiSend
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10px",
                        marginRight: "2px",
                      }}
                      color={"#3B37DA"}
                      fontSize={24}
                    />
                  </a>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              height: "100vh",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FiLoader size={32} />
            <p
              style={{
                marginBottom: "0px",
                paddingLeft: "12px",
                color: "white",
              }}
            >
              {" "}
              Connecting to Opal Live
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default OpalLiveControls;
