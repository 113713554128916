import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import Avatar from "components/common/Avatar";
import { useDispatch } from "react-redux";
import * as actions from "../../../store/actions";

const ProfileDropdown = () => {
  const dispatch = useDispatch();

  const Logout = () => {
    dispatch(actions.authLogout());
    window.location.href = "/";
  };
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={require("../../../assets/backgrounds/ic_opal_logo.png")} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item onClick={() => Logout()}>Logout</Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
