import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "./MainLayout";

import ErrorLayout from "./ErrorLayout";

import Error404 from "components/errors/Error404";
import Error500 from "components/errors/Error500";

import SimpleLogin from "components/pages/Login";

import Podcasts from "components/podcasts/podcasts";
import Vendors from "components/vendors/vendors";
import VendorView from "components/vendors/details";

import Customers from "components/Customers/Customers";
import CustomerView from "components/Customers/SingleCustomer";

import ItemAuctionView from "components/itemAuction/view";
import AddItem from "components/itemAuction/addItem";
import UpdateItem from "components/itemAuction/updateItem";
import Support from "components/support/support";
import SupportView from "components/support/view";
import OpalLiveControls from "components/OpalLive/OpalLive";
import Dashboard from "components/dashboard/dashboard";
import Products from "components/products/products";
import Orders from "components/orders/Orders";
import ProductTypes from "components/productTypes/productTypes";
import ProductShapes from "components/productShapes/productShapes";
import ProductPatterns from "components/productPatterns/productPatterns";
import LiveAuctionReports from "components/LiveAuctionReports/LiveAuctionReports";
import SingleOrder from "components/orders/SingleOrder";

const Layout = () => {
  return (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>

        {/* Authentication */}
        <Route path="/" element={<SimpleLogin />} />

        <Route element={<MainLayout />}>
          {/* Dashboard Routes */}
          <Route path="/dashboard" element={<Dashboard />} />

          {/* Customers */}
          <Route path="/customers" element={<Customers />} />
          <Route path="/customer/details/:id" element={<CustomerView />} />

          {/* Vendors */}
          <Route path="/vendors" element={<Vendors />} />

          {/* Filters & Options */}
          <Route path="/product/types" element={<ProductTypes />} />
          <Route path="/product/shapes" element={<ProductShapes />} />
          <Route path="/product/patterns" element={<ProductPatterns />} />

          {/* Order Routes */}
          <Route path="/orders" element={<Orders />} />
          <Route path="/order/:id" element={<SingleOrder />} />

          <Route path="/live/reports" element={<LiveAuctionReports />} />

          <Route path="/products" element={<Products />} />
          <Route path="/podcasts" element={<Podcasts />} />

          <Route path="/vendor/view" element={<VendorView />} />
          <Route path="/item-auction/view" element={<ItemAuctionView />} />
          <Route path="/item-auction/add" element={<AddItem />} />
          <Route path="/item-auction/update" element={<UpdateItem />} />
          <Route path="/support" element={<Support />} />
          <Route path="/support/view" element={<SupportView />} />
        </Route>

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />

        <Route
          path="stream-controller"
          element={<Navigate to="/stream-controls" replace />}
        />
        <Route path="stream-controls" element={<OpalLiveControls />} />
      </Routes>
    </>
  );
};

export default Layout;
