import React, { useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import NavbarTop from "components/navbar/top/NavbarTop";
import NavbarVertical from "components/navbar/vertical/NavbarVertical";
import AppContext from "context/Context";
import Footer from "components/footer/Footer";
import ProductProvider from "components/app/e-commerce/ProductProvider";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

const MainLayout = () => {
  const { hash, pathname } = useLocation();

  const {
    config: { isFluid, navbarPosition },
  } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {}, []);
  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const isLogin = localStorage.getItem("accessToken") || false;
    if (isLogin === null || isLogin === false) {
      navigate("/");
    }
  }, []);

  return (
    // <div className={isFluid ? 'container-fluid' : 'container'}>
    <div className="container">
      {(navbarPosition === "vertical" || navbarPosition === "combo") && (
        <NavbarVertical />
      )}
      <ProductProvider>
        <div className={classNames("content")}>
          <NavbarTop />
          <Outlet />
          <Footer />
        </div>
      </ProductProvider>
    </div>
  );
};

export default MainLayout;
