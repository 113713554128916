import React from "react";

const Footer = () => (
  <footer className="footer">
    <div className="d-flex flex-row justify-content-center text-center mb-4">
      <p className="mb-0" style={{ color: "#000000", fontWeight: "400" }}>
        Copyright © Opal Empire & Jewellery 2020 - {new Date().getFullYear()}
      </p>

      <div style={{ flex: 1 }} />

      <p className="mb-0" style={{ color: "#000000", fontWeight: "400" }}>
        Intergalactically Designed & Engineered by{" "}
        <a style={{ color: "#AD0FE1" }} href="https://interstellr.com.au">
          Interstellr
        </a>
      </p>
    </div>
  </footer>
);

export default Footer;
