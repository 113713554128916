import LoadingIcon from "common_components/LoadingIcon";
import React, { useEffect, useState } from "react";
import Http from "security/Http";

const Products = () => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const getProducts = () => {
    Http.callApi(["get", "admin/products/get"]).then((response) => {
      console.log("Products Response: ", response.data);

      if (!response.data.success) {
        alert(response.data?.message ?? "");
        return;
      }

      setProducts(response.data.data);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  };

  useEffect(() => {
    document.title =
      "Products | Opal Empire & Jewellery | Buy & Sell Opal Worldwide";
    getProducts();
  }, []);

  return (
    <>
      {/* Header Area */}
      <div
        className="d-flex align-items-center bg-white px-3 py-3 mb-4"
        style={{ width: "100%", borderRadius: 8 }}
      >
        <h4 className="mb-0 pb-0">Products</h4>

        <div style={{ flex: 1 }} />

        <h4 className="mb-0 pb-0">{products ? products?.length : 0} Found</h4>
      </div>

      {/* Content Area */}
      {!loading ? (
        <div className="row">
          {products && products.length > 0
            ? products.map((product) => {
                const productImg = product?.image.find(
                  (item) => item.type === "photo"
                );

                return (
                  <div key={product._id} className="col-3 px-2 mb-3">
                    <div
                      className="bg-white position-relative"
                      style={{ borderRadius: 8 }}
                    >
                      <div
                        className="badge"
                        style={{
                          backgroundColor: product.is_active ? "green" : "red",
                          position: "absolute",
                          top: "12px",
                          right: "12px",
                          paddingLeft: 12,
                          paddingRight: 12,
                          zIndex: "1",
                          borderRadius: 32,
                        }}
                      >
                        {product.is_active ? "Available" : "Unavailable"}
                      </div>

                      <div
                        style={{
                          backgroundSize: "cover",
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                        }}
                      >
                        <img
                          style={{
                            height: 216,
                            width: "100%",
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            objectFit: "cover",
                          }}
                          src={
                            productImg.item ??
                            "https://opalempire-production.s3.ap-southeast-2.amazonaws.com/user_images/ic_blank.webp"
                          }
                        />
                      </div>

                      <div className="px-3 py-3">
                        <p
                          className="pb-2 mb-0"
                          style={{
                            color: "#7f37da",
                            fontWeight: "500",
                            height: 28,
                            overflow: "hidden",
                          }}
                        >
                          {product?.business_id?.name ?? ""}
                        </p>

                        <p
                          className="pb-2 mb-0"
                          style={{
                            color: "#000000",
                            fontWeight: "600",
                            height: 64,
                            overflow: "hidden",
                          }}
                        >
                          {product.name ?? ""}
                        </p>

                        <p
                          className="pb-2 mb-0"
                          style={{
                            color: "#3b37da",
                            fontWeight: "600",
                            height: 32,
                            overflow: "hidden",
                          }}
                        >
                          ${product?.price ? product?.price?.toFixed(2) : ""}{" "}
                          AUD{" "}
                          {product?.business_id?.gst_registered ? "+ GST" : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      ) : (
        <>
          <div
            style={{
              height: "70vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingIcon />
            <p style={{ color: "#000000", fontWeight: "500" }}>
              Updating Products
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default Products;
