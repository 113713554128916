import React, { useState, useEffect } from "react";
import UserProfileContext from "./contexts/UserProfileContext";

export const UserProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(() => {
    // Retrieve stored data from localStorage
    const savedProfile = localStorage.getItem("userProfile");
    return savedProfile ? JSON.parse(savedProfile) : null;
  });

  useEffect(() => {
    // Store data in localStorage whenever it changes
    localStorage.setItem("userProfile", JSON.stringify(profile));
  }, [profile]);

  return (
    <UserProfileContext.Provider value={{ profile, setProfile }}>
      {children}
    </UserProfileContext.Provider>
  );
};
