import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Http from "../../security/Http";
import LoadingIcon from "common_components/LoadingIcon";
import ChartComponent from "components/components/ReactChart";

const Dashboard = () => {
  const navigation = useNavigate();

  // Restaurant Dashboard Analytics
  const [statsData, setStatsData] = useState({});

  const [customersCount, setCustomersCount] = useState(0);
  const [vendorsCount, setVendorsCount] = useState(0);
  const [activeVendorsCount, setActiveVendorsCount] = useState(0);
  const [productsCount, setProductsCount] = useState(0);

  const [giveawayAttempts, setGiveawayAttempts] = useState(0);
  const [giveawaysComplete, setGiveawaysComplete] = useState(0);

  const [loading, setLoading] = useState(true);

  const getStatistics = () => {
    Http.callApi(["get", "admin/statistics/get"]).then((response) => {
      console.log(response.data);
      if (response.data.success == true) {
        setStatsData(response.data.data);
        setLoading(false);
      }
    });
  };

  const getCustomers = () => {
    Http.callApi(["get", "admin/customers/count"])
      .then((response) => {
        console.log(response.data);
        if (response.data.success == true) {
          setCustomersCount(response.data.data.length);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getVendors = () => {
    Http.callApi(["get", "admin/vendors/count"])
      .then((response) => {
        console.log(response.data);
        if (response.data.success == true) {
          setVendorsCount(response.data.data.length);
          setActiveVendorsCount(response.data.data.active);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getProducts = () => {
    Http.callApi(["get", "admin/products/count"])
      .then((response) => {
        console.log(response.data);
        if (response.data.success == true) {
          setProductsCount(response.data.data.length);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getGiveaways = () => {
    Http.callApi(["get", "admin/giveaway/records/count"])
      .then((response) => {
        console.log(response.data);
        if (response.data.success == true) {
          setGiveawayAttempts(response.data.data.attempts);
          setGiveawaysComplete(response.data.data.complete);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  useState(() => {
    document.title =
      "Dashboard | Opal Empire & Jewellery | Buy & Sell Opal Worldwide";
    getStatistics();
    // getCustomers();
    // getVendors();
    // getProducts();
    // getGiveaways();
  }, []);

  // useEffect(() => {
  //   getUser();
  //   getVendor();
  //   getItem();
  //   getOrder();

  //   // Dashboard APIs
  //   getRevenue();
  //   getAverageOrderValue();
  //   getAllTimeRefunds();
  //   getActiveVisits();
  //   getCartAbandonmentValue();
  //   getOrderFrequency();
  // }, []);

  // const getVendor = () => {
  //   dispatch(vendorService.getData())
  //     .then((res) => {
  //       setVendors(res.data.length);
  //     })
  //     .catch((errors) => {
  //       console.log(errors);
  //     });
  // };

  // const getUser = () => {
  //   dispatch(UserService.getData())
  //     .then((res) => {
  //       setUsers(res.data.length);
  //     })
  //     .catch((errors) => {
  //       console.log(errors);
  //     });
  // };

  // const getItem = () => {
  //   dispatch(UserItem.getData())
  //     .then((res) => {
  //       setItems(res.data.length);
  //       setItemsData(res.data);
  //     })
  //     .catch((errors) => {
  //       console.log(errors);
  //     });
  // };

  // const getOrder = () => {
  //   Http.callApi(url.get_order_count)
  //     .then(function (res) {
  //       setOrders(res.data.orderCount);
  //     })
  //     .catch(function (err) {
  //       console.log(err);
  //     });
  // };

  // // Dashboard Statistics APIs
  // const getRevenue = () => {
  //   Http.callApi(url.get_dashboard_revenue)
  //     .then(function (res) {
  //       console.log(res.data);
  //       setRevenue(res.data.revenue);
  //     })
  //     .catch(function (err) {
  //       console.log(err);
  //     });
  // };

  // const getAverageOrderValue = () => {
  //   Http.callApi(url.get_average_order_value)
  //     .then(function (res) {
  //       console.log(res.data);
  //       setAverageValue(res.data.average_order_value);
  //     })
  //     .catch(function (err) {
  //       console.log(err);
  //     });
  // };

  // const getAllTimeRefunds = () => {
  //   Http.callApi(url.get_all_time_refunds)
  //     .then(function (res) {
  //       console.log(res.data);
  //       setTotalRefunds(res.data.refunded_value);
  //     })
  //     .catch(function (err) {
  //       console.log(err);
  //     });
  // };

  // const getActiveVisits = () => {
  //   Http.callApi(url.get_active_visits)
  //     .then(function (res) {
  //       console.log(res.data);
  //       setActiveVisits(res.data.active_visits);
  //     })
  //     .catch(function (err) {
  //       console.log(err);
  //     });
  // };

  // const getCartAbandonmentValue = () => {
  //   Http.callApi(url.get_cart_abandonment_value)
  //     .then(function (res) {
  //       console.log(res.data);
  //       setCartAbandonment(res.data.cart_abandonment);
  //     })
  //     .catch(function (err) {
  //       console.log(err);
  //     });
  // };

  // const getOrderFrequency = () => {
  //   Http.callApi(url.get_order_frequency)
  //     .then(function (res) {
  //       console.log(res.data);
  //       setOrderFrequency(res.data.average_difference);
  //     })
  //     .catch(function (err) {
  //       console.log(err);
  //     });
  // };

  return (
    <>
      {!loading ? (
        <>
          <div
            style={{
              paddingLeft: "16px",
              paddingRight: "16px",
              marginBottom: "16px",
            }}
          >
            <button
              onClick={() => {
                navigation("/stream-controller", { replace: true });
              }}
              style={{
                backgroundColor: "#3b37da",
                width: "100%",
                border: "0px",
                color: "white",
                paddingTop: 12,
                paddingBottom: 12,
                borderRadius: 8,
              }}
            >
              Enter Opal Live Control Centre
            </button>
          </div>

          <div className="container">
            <ChartComponent data={statsData?.customer_growth_data ?? []} />
          </div>

          {/* <div class="container">
            <div class="row">
              <div class="col">
                <WeeklySales
                  data={{
                    title: "Active Customers",
                    total: customersCount,
                  }}
                />
              </div>
              <div class="col">
                <WeeklySales
                  data={{
                    title: "Total Vendors",
                    total: vendorsCount,
                    icon: ["fas", "users"],
                  }}
                />
              </div>
              <div class="col">
                <WeeklySales
                  data={{
                    title: "Active Vendors",
                    total: activeVendorsCount,
                    icon: ["fas", "users"],
                  }}
                />
              </div>
              <div class="col">
                <WeeklySales
                  data={{
                    title: "Active Items",
                    total: productsCount,
                    icon: "shopping-cart",
                  }}
                />
              </div>
            </div>
          </div> */}

          {/* <div class="container mt-3">
            <div class="row">
              <div class="col">
                <WeeklySales
                  data={{
                    title: "Subscription Attempts",
                    total: giveawayAttempts,
                  }}
                />
              </div>
              <div class="col">
                <WeeklySales
                  data={{
                    title: "Active Subscriptions",
                    total: giveawaysComplete,
                    icon: ["fas", "users"],
                  }}
                />
              </div>
              <div class="col"> */}
          {/* <WeeklySales
              data={{
                title: "Active Vendors",
                total: activeVendorsCount,
                icon: ["fas", "users"],
              }}
            /> */}
          {/* </div>
              <div class="col"> */}
          {/* <WeeklySales
              data={{
                title: "Active Items",
                total: productsCount,
                icon: "shopping-cart",
              }}
            /> */}
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
        </>
      ) : (
        <>
          <>
            <div
              style={{
                height: "80vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingIcon />
              <p style={{ color: "#000000", fontWeight: "500" }}>
                Updating Dashboard & Statistics
              </p>
            </div>
          </>
        </>
      )}
    </>
  );
};

export default Dashboard;
