import React, { useState, useEffect } from "react";
import Http from "../../security/Http";

import { HiMiniChevronRight } from "react-icons/hi2";
import LoadingIcon from "common_components/LoadingIcon";
import { useNavigate } from "react-router";

const Customers = () => {
  const navigation = useNavigate();
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState([]);

  const getCustomers = async () => {
    Http.callApi(["get", "admin/customers/get"]).then((response) => {
      console.log("Customers Response: ", response.data);

      if (!response.data.success) {
        alert(response.data?.message ?? "");
        return;
      }

      setCustomers(response.data.data);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  };

  useEffect(() => {
    document.title =
      "Customers | Opal Empire & Jewellery | Buy & Sell Opal Worldwide";
    getCustomers();
  }, []);

  return (
    <>
      {/* Header Area */}
      <div
        className="d-flex align-items-center bg-white px-3 py-3 mb-4"
        style={{ width: "100%", borderRadius: 8 }}
      >
        <h4 className="mb-0 pb-0">Customers</h4>

        <div style={{ flex: 1 }} />

        <h4 className="mb-0 pb-0">{customers ? customers?.length : 0} Found</h4>
      </div>

      {/* Content Area */}
      {!loading ? (
        <div className="row">
          {customers && customers.length > 0
            ? customers.map((user) => (
                <div
                  onClick={() => navigation(`/customer/details/${user._id}`)}
                  key={user._id}
                  className="col-3 px-2 mb-3 cursor-pointer"
                >
                  <div
                    className="bg-white position-relative"
                    style={{ borderRadius: 8 }}
                  >
                    <div
                      className="badge"
                      style={{
                        backgroundColor: "#3b37da",
                        position: "absolute",
                        top: "12px",
                        right: "12px",
                        paddingLeft: 12,
                        paddingRight: 12,
                        zIndex: "1",
                        borderRadius: 32,
                      }}
                    >
                      {user.device === 1
                        ? "Android"
                        : user.device === 2
                        ? "iOS"
                        : user.device === 3
                        ? "Webapp"
                        : "Unknown"}
                    </div>

                    {/* Chevron icon positioned below the badge */}
                    <HiMiniChevronRight
                      size={24}
                      style={{
                        position: "absolute",
                        top: "56px", // Adjust top value as needed
                        right: "16px", // Adjust right value to align under the badge
                        color: "#ffffff", // Optional: Match the badge color or use a different one
                        zIndex: "1",
                      }}
                    />

                    <div
                      className="px-3 py-4"
                      style={{
                        backgroundColor: "#0D0E11",
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                      }}
                    >
                      <img
                        style={{
                          height: 64,
                          width: 64,
                          borderRadius: 64,
                          objectFit: "cover",
                        }}
                        src={
                          user?.image ??
                          "https://opalempire-production.s3.ap-southeast-2.amazonaws.com/user_images/ic_blank.webp"
                        }
                      />
                    </div>

                    <div className="px-3 py-3">
                      <p className="pb-2 mb-0" style={{ color: "#000000" }}>
                        {user.first_name ?? ""} {user?.last_name ?? ""}
                      </p>

                      <p
                        className="pb-0 mb-0"
                        style={{
                          color: "#3b37da",
                          overflow: "hidden",
                        }}
                      >
                        {user.email ?? ""}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      ) : (
        <>
          <div
            style={{
              height: "70vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingIcon />
            <p style={{ color: "#000000", fontWeight: "500" }}>
              Updating Customers
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default Customers;
