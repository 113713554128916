import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Http from "security/Http";

const SingleOrder = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({});

  useEffect(() => {
    getOrderDetails();
  }, []);

  const getOrderDetails = async () => {
    Http.callApi(["get", `admin/orders/details/${id}`]).then((response) => {
      console.log("Order Details Response: ", response.data);
    });
  };

  return (
    <>
      <div></div>
    </>
  );
};

export default SingleOrder;
