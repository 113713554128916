import React from "react";
import Lottie from "react-lottie";

import * as loader from "../assets/anims/loader_lottie.json";

const LoadingIcon = ({ dimensions = 150 }) => {
  return (
    <>
      <div>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loader,
          }}
          isClickToPauseDisabled={true}
          height={dimensions}
          width={dimensions}
        />
      </div>
    </>
  );
};

export default LoadingIcon;
