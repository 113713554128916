import LoadingIcon from "common_components/LoadingIcon";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Http from "security/Http";
import TimeAgo from "timeago-react";

import { MdOutlinePayment } from "react-icons/md";
import { FaUnlockAlt, FaSave } from "react-icons/fa";
import { IoReceiptOutline, IoHomeOutline } from "react-icons/io5";
import { FiUserPlus } from "react-icons/fi";

const SingleCustomer = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState({});

  //   Input Fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    getCustomerDetails();
  }, []);

  const getCustomerDetails = async () => {
    Http.callApi(["get", `admin/customers/get/${id}`]).then((response) => {
      console.log("Customer Details Response: ", response.data);

      if (!response.data.success) {
        alert(response.data?.message ?? "");
        return;
      }

      setCustomer(response.data.data);
      if (response.data.data) {
        setFirstName(response.data.data.first_name);
        setLastName(response.data.data.last_name);
        setEmailAddress(response.data.data.email);
        setPhone(response.data.data.mobile);
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  };

  return (
    <>
      {!loading ? (
        <>
          <div>
            {/* Profile Header */}
            <div
              className="bg-black flex px-3 py-3"
              style={{
                borderRadius: 12,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <img
                className="img-fluid rounded-circle"
                style={{ width: "4rem", height: "4rem" }}
                src={customer?.image ?? ""}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 16,
                }}
              >
                <p
                  style={{
                    color: "white",
                    marginBottom: 0,
                    paddingBottom: 0,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  {customer?.first_name ?? ""} {customer?.last_name ?? ""}
                </p>

                <p
                  style={{
                    color: "white",
                    marginBottom: 0,
                    paddingBottom: 0,
                    fontSize: 15,
                    fontWeight: "400",
                  }}
                >
                  {customer?.email ?? ""}
                </p>

                <p
                  style={{
                    color: "white",
                    marginTop: 8,
                    marginBottom: 0,
                    paddingBottom: 0,
                    fontSize: 15,
                    fontWeight: "600",
                  }}
                >
                  Joined{" "}
                  <TimeAgo
                    datetime={customer?.createdAt ?? new Date().toString()}
                    locale={"en_AU"}
                  />
                </p>
              </div>
            </div>

            {/* Profile Content */}
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 16 }}
            >
              {/* Left Column */}
              <div style={{ width: "49%" }}>
                <p style={{ color: "black", fontWeight: "600", fontSize: 16 }}>
                  User Details
                </p>
                {/* First Name & Last Name */}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ width: "49%" }}>
                    <p
                      style={{
                        color: "black",
                        fontWeight: "600",
                        fontSize: 16,
                        marginBottom: 8,
                        paddingBottom: 0,
                      }}
                    >
                      First Name
                    </p>
                    <input
                      type={"text"}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="form-control"
                      placeholder={"Jullian"}
                    />
                  </div>

                  <div style={{ flex: 1 }} />

                  <div style={{ width: "49%" }}>
                    <p
                      style={{
                        color: "black",
                        fontWeight: "600",
                        fontSize: 16,
                        marginBottom: 8,
                        paddingBottom: 0,
                      }}
                    >
                      Last Name
                    </p>
                    <input
                      type={"text"}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="form-control"
                      placeholder={"Stavreas"}
                    />
                  </div>
                </div>

                {/* Email */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 12,
                  }}
                >
                  <p
                    style={{
                      color: "black",
                      fontWeight: "600",
                      fontSize: 16,
                      marginBottom: 8,
                      paddingBottom: 0,
                    }}
                  >
                    Email Address
                  </p>
                  <input
                    type={"email"}
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                    className="form-control"
                    placeholder={"hello@opalempire.com.au"}
                  />
                </div>

                {/* Phone */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 12,
                  }}
                >
                  <p
                    style={{
                      color: "black",
                      fontWeight: "600",
                      fontSize: 16,
                      marginBottom: 8,
                      paddingBottom: 0,
                    }}
                  >
                    Phone
                  </p>
                  <input
                    type={"number"}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="form-control"
                    placeholder={"+61400000001"}
                  />
                </div>

                <div
                  style={{
                    marginTop: 12,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#3b37da",
                    borderRadius: 12,
                  }}
                >
                  <FaSave color={"#ffffff"} size={20} />

                  <p
                    style={{
                      color: "#ffffff",
                      fontSize: 15,
                      fontWeight: "600",
                      paddingTop: 12,
                      paddingBottom: 12,
                      marginBottom: 0,
                      paddingLeft: 12,
                      paddingRight: 12,
                    }}
                  >
                    Save User Profile
                  </p>
                </div>
              </div>

              <div style={{ flex: 1 }} />

              {/* Right Column */}
              <div style={{ width: "49%" }}>
                <p style={{ color: "black", fontWeight: "600", fontSize: 16 }}>
                  User Actions
                </p>

                <div
                  style={{
                    marginTop: 12,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#0D0E11",
                    borderRadius: 12,
                  }}
                >
                  <FaUnlockAlt color={"#ffffff"} size={20} />

                  <p
                    style={{
                      color: "#ffffff",
                      fontSize: 15,
                      fontWeight: "600",
                      paddingTop: 12,
                      paddingBottom: 12,
                      marginBottom: 0,
                      paddingLeft: 12,
                      paddingRight: 12,
                    }}
                  >
                    Change Password
                  </p>
                </div>

                <div
                  style={{
                    marginTop: 12,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#3b37da",
                    borderRadius: 12,
                  }}
                >
                  <MdOutlinePayment color={"#ffffff"} size={20} />

                  <p
                    style={{
                      color: "#ffffff",
                      fontSize: 15,
                      fontWeight: "600",
                      paddingTop: 12,
                      paddingBottom: 12,
                      marginBottom: 0,
                      paddingLeft: 12,
                      paddingRight: 12,
                    }}
                  >
                    View Stripe Customer Account
                  </p>
                </div>

                <div
                  style={{
                    marginTop: 12,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#3b37da",
                    borderRadius: 12,
                  }}
                >
                  <IoReceiptOutline color={"#ffffff"} size={20} />

                  <p
                    style={{
                      color: "#ffffff",
                      fontSize: 15,
                      fontWeight: "600",
                      paddingTop: 12,
                      paddingBottom: 12,
                      marginBottom: 0,
                      paddingLeft: 12,
                      paddingRight: 12,
                    }}
                  >
                    View Customer Orders
                  </p>
                </div>

                <div
                  style={{
                    marginTop: 12,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#3b37da",
                    borderRadius: 12,
                  }}
                >
                  <FiUserPlus color={"#ffffff"} size={20} />

                  <p
                    style={{
                      color: "#ffffff",
                      fontSize: 15,
                      fontWeight: "600",
                      paddingTop: 12,
                      paddingBottom: 12,
                      marginBottom: 0,
                      paddingLeft: 12,
                      paddingRight: 12,
                    }}
                  >
                    View Subscription Records
                  </p>
                </div>

                <div
                  style={{
                    marginTop: 12,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#3b37da",
                    borderRadius: 12,
                  }}
                >
                  <IoHomeOutline color={"#ffffff"} size={20} />

                  <p
                    style={{
                      color: "#ffffff",
                      fontSize: 15,
                      fontWeight: "600",
                      paddingTop: 12,
                      paddingBottom: 12,
                      marginBottom: 0,
                      paddingLeft: 12,
                      paddingRight: 12,
                    }}
                  >
                    View Customer Addresses
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              height: "70vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingIcon />
            <p style={{ color: "#000000", fontWeight: "500" }}>
              Updating Customer Details
            </p>
          </div>
        </>
      )}
      {/* <div
        style={{ borderRadius: "0.375rem" }}
        className="py-4 bg-white mb-3 d-flex align-items-center px-3"
      >
        <h5 className="hover-actions-trigger mb-0">User Details</h5>
      </div>

      <div className="col-lg-8 mx-auto">
        <Card className="p-4">
          <div className="row row-cols-1 g-3">
            <div className="col d-flex justify-content-center">
              <div className="row">
                <div className="col-6 flex-nowrap">
                  <div className="user_detail_img_container">
                    {data.image ? (
                      <GetImage
                        key={data._id}
                        im={data.image}
                        ImageData={ImageUrl}
                      />
                    ) : (
                      ""
                    )}
                    <img src={imgSrc} alt="user" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <hr className="w-100 bg-primary my-2" />
            </div>
            <div className="col">
              <div className="row">
                <div className="col-4 user_view_label">Name</div>
                <div className="col-2">:</div>
                <div className="col-6 flex-nowrap">
                  {data?.first_name} {data?.last_name}
                </div>
              </div>
            </div>
            <div className="col">
              <div className="row">
                <div className="col-4 user_view_label">Email</div>
                <div className="col-2">:</div>
                <div className="col-6 flex-nowrap">{data?.email ?? "-"}</div>
              </div>
            </div>
            <div className="col">
              <div className="row">
                <div className="col-4 user_view_label">is_verify</div>
                <div className="col-2">:</div>
                <div className="col-6 flex-nowrap">
                  {data?.is_verify == 1 ? "Yes" : "No"}
                </div>
              </div>
            </div>
            {data?.addresses?.map((item, index) => {
              return (
                <>
                  <div className="col">
                    <hr className="w-100 bg-primary my-2" />
                  </div>
                  <center>
                    <h3> Address {index + 1}</h3>
                  </center>
                  <div className="col">
                    <div className="row">
                      <div className="col-4 user_view_label">
                        Address Line 1
                      </div>
                      <div className="col-2">:</div>
                      <div className="col-6 flex-nowrap">
                        {item.address_line1 ?? "-"}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="col-4 user_view_label">
                        Address Line 2
                      </div>
                      <div className="col-2">:</div>
                      <div className="col-6 flex-nowrap">
                        {item.address_line2 ?? "-"}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="col-4 user_view_label">Area</div>
                      <div className="col-2">:</div>
                      <div className="col-6 flex-nowrap">
                        {item.area ?? "-"}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="col-4 user_view_label">Direction</div>
                      <div className="col-2">:</div>
                      <div className="col-6 flex-nowrap">
                        {item.direction ?? "-"}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="col-4 user_view_label">
                        Location Description
                      </div>
                      <div className="col-2">:</div>
                      <div className="col-6 flex-nowrap">
                        {item.location_description ?? "-"}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="col-4 user_view_label">Lable</div>
                      <div className="col-2">:</div>
                      <div className="col-6 flex-nowrap">
                        {item.lable ?? "-"}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="col-4 user_view_label">Location Name</div>
                      <div className="col-2">:</div>
                      <div className="col-6 flex-nowrap">
                        {item.location_name ?? "-"}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </Card>
      </div> */}
    </>
  );
};
export default SingleCustomer;
