import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { FaRegEye, FaEye } from "react-icons/fa";

import backgroundImage from "../../assets/backgrounds/ic_login_bg.png";
import Logo from "../../assets/backgrounds/ic_opal_logo.png";
import Http from "security/Http";
import UserProfileContext from "providers/contexts/UserProfileContext";
import { setBearerToken } from "security/Axios";

const Login = () => {
  const { setProfile } = useContext(UserProfileContext);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    document.title =
      "Administrator Login | Opal Empire & Jewellery | Buy & Sell Opal Worldwide";
    const isLogin = localStorage.getItem("accessToken") ?? false;
    if (isLogin) {
      navigate("/dashboard");
    }
  }, []);

  const loginUser = () => {
    Http.callApi(["post", "admin/auth/login"], {
      email: username,
      password: password,
    })
      .then((res) => {
        console.log("LOGIN RESPONSE: ", res.data);
        if (!res.data.success) {
          alert(res.data?.message ?? "");
          return;
        }

        localStorage.setItem("accessToken", res.data.data.access_token);
        setBearerToken(res.data.data.access_token);
        setProfile(res.data.data.profile);
        navigate("/dashboard");
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <>
      <div
        className="d-flex flex-column align-items-center justify-content-center min-vh-100 overflow-hidden w-100 px-4"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="row w-100">
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
            <Link to={"/"}>
              <img
                src={Logo}
                className="img-fluid"
                style={{ height: "356px", width: "356px" }}
                alt="Opal Empire"
              />
            </Link>
          </div>

          <div className="col-12 col-md-6 d-flex justify-content-start align-items-center">
            <div
              className="card p-3 p-md-4 w-100"
              style={{ maxWidth: "500px", borderRadius: "16px" }}
            >
              <h4
                className="mb-2 mt-2"
                style={{ fontWeight: "600", color: "black" }}
              >
                Login to Opal Empire
              </h4>
              <p>Login to your Administrator account</p>

              <div
                className="mt-2 mb-4"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="flex-grow-1 border-top border-secondary" />
                <p className="text-secondary pb-0 mb-0 px-4">
                  Administrator Login
                </p>
                <div className="flex-grow-1 border-top border-secondary" />
              </div>

              <div className="flex-column">
                <div
                  className="mb-3 border rounded"
                  style={{
                    height: "45px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    type={"text"}
                    id="email"
                    style={{ width: "100%", height: "100%" }}
                    onChange={(val) => setUsername(val.target.value)}
                    className="form-control"
                    placeholder="Email address"
                  />
                </div>

                <div
                  className="mb-3 border rounded"
                  style={{
                    height: "45px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    style={{ width: "100%", height: "100%" }}
                    onChange={(val) => setPassword(val.target.value)}
                    className="form-control"
                    placeholder="Password"
                  />
                  <div
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    className="cursor-pointer"
                  >
                    {!showPassword ? (
                      <FaRegEye
                        style={{ color: showPassword ? "#000" : "#a6a9b3" }}
                      />
                    ) : (
                      <FaEye
                        style={{ color: showPassword ? "#000" : "#a6a9b3" }}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div
                onClick={() => loginUser()}
                style={{
                  height: "45px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "12px",
                  backgroundColor: "#3b37da",
                }}
                className="btn w-100 mt-3 mb-2"
              >
                <p className="text-white m-0">Login</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  // return (
  //   <>
  //     <center>
  //       <h3>Admin Login</h3>
  //     </center>
  //     <LoginForm layout="card" hasLabel />
  //   </>
  // );
};

export default Login;
