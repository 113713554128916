import React, { useContext, useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import classNames from "classnames";
import AppContext from "context/Context";
import Logo from "components/common/Logo";
import SearchBox from "./SearchBox";
import NavbarTopDropDownMenus from "./NavbarTopDropDownMenus";
import { navbarBreakPoint, topNavbarBreakpoint } from "config";
import autoCompleteInitialItem from "data/autocomplete/autocomplete";
import TopNavRightSideNavItem from "./TopNavRightSideNavItem";

const NavbarTop = () => {
  const {
    config: { showBurgerMenu, navbarPosition, navbarCollapsed },
    setConfig,
  } = useContext(AppContext);

  return (
    <Navbar
      className={classNames("navbar-glass  fs--1 navbar-top sticky-kit")}
      expand={
        navbarPosition === "top" || navbarPosition === "combo"
          ? topNavbarBreakpoint
          : true
      }
    >
      <Logo at="navbar-top" width={86} id="topLogo" />

      {navbarPosition === "top" || navbarPosition === "combo" ? (
        <Navbar.Collapse
          in={navbarCollapsed}
          className="scrollbar pb-3 pb-lg-0"
        >
          <Nav navbar>
            <NavbarTopDropDownMenus />
          </Nav>
        </Navbar.Collapse>
      ) : (
        <Nav
          navbar
          className={`align-items-center d-none d-${topNavbarBreakpoint}-block`}
          as="ul"
        >
          <Nav.Item as="li">
            <SearchBox autoCompleteItem={autoCompleteInitialItem} />
          </Nav.Item>
        </Nav>
      )}

      <TopNavRightSideNavItem />
    </Navbar>
  );
};

export default NavbarTop;
