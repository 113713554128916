import LoadingIcon from "common_components/LoadingIcon";
import React, { useState, useEffect } from "react";
import Http from "security/Http";

import { HiMiniChevronRight } from "react-icons/hi2";

const ProductShapes = () => {
  const [loading, setLoading] = useState(true);
  const [shapes, setShapes] = useState([]);

  const getProductShapes = () => {
    Http.callApi(["get", "admin/products/shapes/get"]).then((response) => {
      console.log("Product Shapes Response: ", response.data);

      if (!response.data.success) {
        alert(response.data?.message ?? "");
        return;
      }

      setShapes(response.data.data);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  };

  useEffect(() => {
    document.title =
      "Product Shapes | Opal Empire & Jewellery | Buy & Sell Opal Worldwide";
    getProductShapes();
  }, []);

  return (
    <>
      {/* Header Area */}
      <div
        className="d-flex align-items-center bg-white px-3 py-3 mb-4"
        style={{ width: "100%", borderRadius: 8 }}
      >
        <h4 className="mb-0 pb-0">Product Shapes</h4>

        <div style={{ flex: 1 }} />

        <h4 className="mb-0 pb-0">{shapes ? shapes?.length : 0} Found</h4>
      </div>

      {/* Content Area */}
      {!loading ? (
        <div className="row">
          {shapes && shapes.length > 0
            ? shapes.map((shape) => (
                <div key={shape._id} className="col-3 px-2 mb-3">
                  <div
                    className="bg-white position-relative"
                    style={{ borderRadius: 8 }}
                  >
                    <div className="px-3 py-3 d-flex justify-items-center">
                      <p className="pb-0 mb-0" style={{ color: "#000000" }}>
                        {shape.name ?? ""}
                      </p>

                      <div style={{ flex: 1 }} />

                      <HiMiniChevronRight
                        size={24}
                        style={{
                          color: "#000000",
                          zIndex: "1",
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      ) : (
        <>
          <div
            style={{
              height: "70vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingIcon />
            <p style={{ color: "#000000", fontWeight: "500" }}>
              Updating Product Shapes
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default ProductShapes;
